import * as React from 'react';

function SvgGBLivestream(props) {
  return (
    <svg
      width="552"
      height="160"
      viewBox="0 0 552 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M64.4954 69.3464H69.4298V93.1881C69.4298 97.8647 68.2602 101.203 65.9026 103.238C63.545 105.274 59.6888 106.301 54.334 106.301C51.5298 106.431 48.7293 105.975 46.1099 104.962C44.7736 104.372 43.6195 103.432 42.7678 102.242C41.916 101.052 41.3977 99.6543 41.2668 98.1949H46.2196C46.4022 98.9453 46.7566 99.6427 47.2547 100.232C47.7528 100.821 48.3808 101.285 49.0889 101.588C50.9303 102.235 52.8784 102.521 54.8275 102.431C57.5257 102.63 60.198 101.79 62.3023 100.084C63.0393 99.378 63.6166 98.5215 63.995 97.5722C64.3734 96.6229 64.544 95.6031 64.4954 94.5819V90.8039C63.3589 92.4032 61.8337 93.6843 60.0648 94.5253C58.2958 95.3663 56.3419 95.7393 54.3888 95.6089C50.8852 95.7382 47.4444 94.6499 44.6479 92.5279C43.2272 91.2022 42.0954 89.5958 41.3238 87.8097C40.5521 86.0237 40.1573 84.0966 40.1642 82.1499C40.171 80.2032 40.5794 78.279 41.3637 76.4984C42.1479 74.7178 43.291 73.1195 44.721 71.804C47.4993 69.6873 50.921 68.5989 54.4071 68.7229C59.2807 68.7229 62.6678 70.1962 64.5685 73.1427L64.4954 69.3464ZM64.4954 82.276C64.5414 81.0376 64.3367 79.8026 63.8938 78.6458C63.4509 77.489 62.7789 76.4342 61.9185 75.5453C59.9767 73.8525 57.491 72.9203 54.9189 72.9203C52.3467 72.9203 49.861 73.8525 47.9192 75.5453C47.0653 76.4372 46.4001 77.4934 45.9635 78.65C45.5269 79.8067 45.3281 81.0402 45.3789 82.276C45.3252 83.5011 45.523 84.7242 45.96 85.8694C46.397 87.0146 47.0639 88.0575 47.9192 88.9334C49.8869 90.5708 52.3627 91.4668 54.9189 91.4668C57.475 91.4668 59.9509 90.5708 61.9185 88.9334C62.7795 88.06 63.4525 87.0183 63.8958 85.8731C64.3391 84.7279 64.5432 83.5036 64.4954 82.276Z"
        fill="#1D1D1B"
      />
      <path
        d="M106.858 83.8534H82.9897C83.0546 85.0525 83.3812 86.2227 83.9465 87.2812C84.5118 88.3397 85.302 89.2605 86.2611 89.9788C88.0613 91.3855 90.2848 92.1358 92.5662 92.1063C96.7148 92.1063 99.511 90.5657 100.918 87.4846H106.182C105.105 90.3268 103.094 92.7146 100.48 94.252C98.151 95.6133 95.4986 96.3167 92.8038 96.2877C90.7402 96.4094 88.6737 96.1062 86.7313 95.3965C84.789 94.6869 83.0119 93.5858 81.5094 92.1612C79.105 89.4698 77.7754 85.9825 77.7754 82.3679C77.7754 78.7533 79.105 75.2658 81.5094 72.5744C83.0152 71.1557 84.7933 70.0599 86.7351 69.3536C88.677 68.6473 90.7418 68.3454 92.8038 68.4663C96.3071 68.3409 99.7323 69.5236 102.417 71.7858C103.951 73.2445 105.146 75.0246 105.917 76.9994C106.687 78.9742 107.015 81.0951 106.876 83.2114L106.858 83.8534ZM83.0993 79.9837H101.558C101.44 78.9128 101.113 77.8756 100.596 76.9315C100.078 75.9875 99.3807 75.155 98.5424 74.4818C96.8141 73.1581 94.6845 72.4716 92.5113 72.5378C90.3052 72.4772 88.1428 73.1618 86.3707 74.4818C85.4808 75.1269 84.7315 75.9477 84.169 76.8938C83.6064 77.8399 83.2424 78.8914 83.0993 79.9837Z"
        fill="#1D1D1B"
      />
      <path
        d="M117.823 69.3465V61.3504H122.776V69.3465H130.196V73.2347H122.758V89.282C122.758 91.1159 124.092 92.1062 126.779 92.1062C127.92 92.0905 129.057 91.9739 130.178 91.7578V95.9392C128.362 96.1981 126.529 96.3146 124.695 96.2876C120.138 96.2876 117.86 94.2336 117.86 90.1255V73.2347H112.523V69.3465H117.823Z"
        fill="#1D1D1B"
      />
      <path
        d="M161.776 79.9652C161.778 64.6451 157.332 49.6562 148.983 36.8301C153.461 45.7432 157.774 58.8378 153.881 72.1708C147.028 95.7374 118.956 113.399 109.818 119.157C100.68 124.916 63.8548 144.613 53.1635 141.623C47.7635 140.044 42.5397 137.911 37.5742 135.26C42.3537 144.152 49.463 151.565 58.1345 156.699C65.5462 158.856 73.2253 159.949 80.9426 159.945C125.609 159.945 161.776 124.127 161.776 79.9652Z"
        fill="url(#paint0_linear_603_315)"
      />
      <path
        d="M0 80.167C0 122.734 33.5908 157.542 75.9906 160C66.8527 158.496 55.7045 154.626 47.8277 145.09C32.2019 126.218 33.3167 96.2877 32.6588 81.5975C32.1653 70.7771 34.8702 31.9151 43.3319 24.7443C49.8746 19.2424 54.2059 19.9575 54.2059 19.9575C54.2059 19.9575 36.6064 15.8495 21.1633 26.2298C7.56627 40.8767 0.00425157 60.1493 0 80.167Z"
        fill="url(#paint1_linear_603_315)"
      />
      <path
        d="M147.523 76.4257C149.241 84.0367 147.194 87.613 146.792 88.2182C147.852 86.6777 160.883 67.2009 153.573 44.7897C146.914 31.5782 136.801 20.4325 124.315 12.546C111.829 4.65945 97.4408 0.328885 82.6907 0.0180034C67.9406 -0.292878 53.3839 3.42758 40.5791 10.7811C27.7742 18.1346 17.2033 28.8443 9.99805 41.7636C15.6635 33.5474 25.1669 23.4238 38.819 20.4344C62.7237 15.2993 91.5445 31.7134 100.957 37.0502C110.369 42.3871 145.074 65.5686 147.523 76.4257Z"
        fill="url(#paint2_linear_603_315)"
      />
      <path
        d="M176.727 95.4441V59.8466H181.643V73.4364C183.562 70.4287 186.833 68.9248 191.493 68.9248C195.172 68.8082 198.754 70.1223 201.49 72.5927C204.111 75.2915 205.578 78.911 205.578 82.6796C205.578 86.4482 204.111 90.0677 201.49 92.7665C198.758 95.2435 195.174 96.5587 191.493 96.4344C189.559 96.448 187.64 96.0805 185.846 95.3524C184.168 94.7538 182.705 93.6687 181.643 92.2347V95.5541L176.727 95.4441ZM200.357 82.6063C200.458 81.3329 200.283 80.0527 199.845 78.8534C199.407 77.6541 198.715 76.5641 197.818 75.6582C196.92 74.7524 195.838 74.052 194.645 73.6052C193.453 73.1584 192.178 72.9756 190.909 73.0696C189.639 73.0107 188.37 73.2076 187.177 73.6485C185.985 74.0895 184.892 74.7657 183.964 75.6372C183.072 76.5548 182.38 77.6477 181.93 78.8471C181.48 80.0465 181.283 81.3265 181.35 82.6063C181.253 85.0201 182.062 87.3826 183.617 89.2269C184.54 90.2319 185.676 91.017 186.941 91.5248C188.205 92.0325 189.567 92.2499 190.927 92.1612C192.286 92.2499 193.648 92.0325 194.913 91.5248C196.178 91.017 197.314 90.2319 198.237 89.2269C199.723 87.3594 200.477 85.0113 200.357 82.6246V82.6063Z"
        fill="#1D1D1B"
      />
      <path
        d="M214.176 95.4441V69.53H219.092V74.2799C219.902 72.591 221.17 71.1653 222.751 70.1662C224.332 69.1672 226.161 68.6353 228.029 68.6314C228.987 68.6185 229.944 68.7169 230.88 68.9247V74.2799C229.733 73.9825 228.555 73.8165 227.371 73.7848C226.286 73.7212 225.2 73.8767 224.177 74.2421C223.153 74.6075 222.214 75.1754 221.413 75.9122C220.61 76.7549 219.989 77.7553 219.589 78.8505C219.19 79.9457 219.021 81.1121 219.092 82.2761V95.4441H214.176Z"
        fill="#1D1D1B"
      />
      <path
        d="M236.798 82.4779C236.722 80.6913 237.007 78.9079 237.635 77.2344C238.263 75.561 239.222 74.0321 240.453 72.7395C243.609 70.0858 247.594 68.6314 251.711 68.6314C255.828 68.6314 259.814 70.0858 262.969 72.7395C265.343 75.424 266.654 78.8887 266.654 82.4779C266.654 86.0671 265.343 89.5318 262.969 92.2163C259.814 94.87 255.828 96.3244 251.711 96.3244C247.594 96.3244 243.609 94.87 240.453 92.2163C239.219 90.9255 238.259 89.3969 237.631 87.723C237.003 86.0492 236.719 84.2648 236.798 82.4779ZM261.525 82.4779C261.612 80.072 260.819 77.7173 259.296 75.8573C257.274 73.8931 254.571 72.7949 251.757 72.7949C248.943 72.7949 246.239 73.8931 244.218 75.8573C242.781 77.7669 242.004 80.0944 242.004 82.4871C242.004 84.8798 242.781 87.2072 244.218 89.1169C246.239 91.0811 248.943 92.1793 251.757 92.1793C254.571 92.1793 257.274 91.0811 259.296 89.1169C260.821 87.2513 261.615 84.8898 261.525 82.4779Z"
        fill="#1D1D1B"
      />
      <path
        d="M279.893 77.6361H275.269C275.854 71.6452 280.119 68.6436 288.063 68.6314C290.778 68.5286 293.484 69.0166 295.994 70.0618C297.184 70.5206 298.203 71.3386 298.91 72.4029C299.617 73.4672 299.977 74.7253 299.942 76.0039V89.9605C299.899 90.2619 299.927 90.5691 300.023 90.8579C300.119 91.1467 300.281 91.4091 300.495 91.6244C300.71 91.8396 300.971 92.0019 301.259 92.0983C301.547 92.1947 301.853 92.2224 302.153 92.1796C302.737 92.1758 303.32 92.1082 303.889 91.9779V95.4441C302.613 95.6399 301.324 95.738 300.033 95.7375C299.498 95.8177 298.952 95.784 298.43 95.6385C297.909 95.4931 297.424 95.2391 297.007 94.8931C296.59 94.5472 296.25 94.117 296.009 93.6305C295.769 93.144 295.633 92.6122 295.61 92.0695C294.381 93.5461 292.749 94.6298 290.914 95.1873C288.892 95.9198 286.757 96.2862 284.608 96.2694C281.549 96.5358 278.508 95.5802 276.147 93.6101C275.472 92.9421 274.944 92.1406 274.595 91.2565C274.245 90.3725 274.083 89.4253 274.118 88.475C274.064 87.4235 274.263 86.3742 274.699 85.4164C275.135 84.4586 275.794 83.6205 276.622 82.9731C279.227 81.4191 282.182 80.5544 285.212 80.4604L290.822 79.8737C291.964 79.8031 293.084 79.5299 294.13 79.0667C294.52 78.8291 294.837 78.4868 295.043 78.0785C295.25 77.6702 295.339 77.2122 295.3 76.7559C295.3 73.9132 292.833 72.501 287.862 72.501C282.891 72.501 280.039 74.2066 279.893 77.6361ZM295.318 85.2105V81.891C293.9 82.8783 292.219 83.4151 290.493 83.4316L285.011 84.1101C283.448 84.2419 281.929 84.6978 280.551 85.4489C280.083 85.7704 279.706 86.2079 279.456 86.7188C279.207 87.2298 279.093 87.7968 279.126 88.365C279.126 88.9617 279.279 89.5481 279.571 90.0683C279.862 90.5885 280.281 91.0251 280.789 91.336C282.098 92.1467 283.62 92.5429 285.157 92.4731C287.746 92.563 290.3 91.8454 292.467 90.419C293.344 89.865 294.065 89.0961 294.564 88.1849C295.063 87.2737 295.322 86.2501 295.318 85.2105Z"
        fill="#1D1D1B"
      />
      <path
        d="M333.808 95.4441V92.1246C332.762 93.5395 331.326 94.6168 329.678 95.224C327.859 95.9564 325.917 96.33 323.957 96.3243C320.283 96.4477 316.704 95.1323 313.979 92.6564C311.358 89.9576 309.891 86.3381 309.891 82.5695C309.891 78.801 311.358 75.1814 313.979 72.4827C316.708 70.0134 320.284 68.6991 323.957 68.8147C325.918 68.8021 327.861 69.1759 329.678 69.9151C331.333 70.5185 332.771 71.6041 333.808 73.0328V59.7365H338.742V95.334L333.808 95.4441ZM315.094 82.6062C314.995 85.0108 315.782 87.3676 317.305 89.2269C319.263 91.1359 321.885 92.2038 324.615 92.2038C327.345 92.2038 329.968 91.1359 331.926 89.2269C333.485 87.3844 334.301 85.0222 334.21 82.6062C334.273 81.3269 334.074 80.0482 333.624 78.8494C333.175 77.6506 332.485 76.5573 331.597 75.6372C330.665 74.7651 329.569 74.0887 328.373 73.6478C327.178 73.2069 325.906 73.0103 324.634 73.0696C323.364 72.9756 322.089 73.1583 320.897 73.6052C319.704 74.052 318.622 74.7523 317.725 75.6582C316.827 76.5641 316.135 77.654 315.697 78.8533C315.259 80.0526 315.084 81.3329 315.185 82.6062H315.094Z"
        fill="#1D1D1B"
      />
      <path
        d="M369.539 85.9441H374.765C374.112 89.1496 372.352 92.0204 369.794 94.0502C367.273 95.6776 364.308 96.4727 361.314 96.3244C359.298 96.4699 357.275 96.1545 355.398 95.4016C353.52 94.6486 351.838 93.478 350.477 91.9778C348.276 89.3737 347.129 86.0364 347.26 82.6246C347.121 78.9996 348.378 75.4599 350.769 72.7395C352.125 71.3435 353.762 70.2547 355.571 69.5461C357.381 68.8376 359.32 68.5257 361.26 68.6314C364.404 68.5068 367.512 69.3378 370.178 71.0155C371.397 71.8167 372.429 72.8718 373.205 74.1092C373.981 75.3466 374.482 76.7372 374.674 78.1863H369.447C368.826 74.5184 366.194 72.7944 361.607 72.7944C360.324 72.7099 359.039 72.9129 357.844 73.3887C356.649 73.8645 355.575 74.6012 354.699 75.5454C353.261 77.5689 352.488 79.9924 352.488 82.4779C352.488 84.9633 353.261 87.3867 354.699 89.4102C355.574 90.3567 356.647 91.0962 357.842 91.5751C359.037 92.054 360.323 92.2605 361.607 92.1796C363.467 92.3241 365.313 91.764 366.781 90.6096C368.249 89.4553 369.233 87.7907 369.539 85.9441Z"
        fill="#1D1D1B"
      />
      <path
        d="M387.738 77.6362H383.096C383.681 71.6452 387.946 68.6436 395.889 68.6314C398.605 68.5304 401.31 69.0182 403.821 70.0618C405.009 70.5213 406.025 71.34 406.729 72.4046C407.433 73.4691 407.79 74.7269 407.75 76.0039V89.9605C407.711 90.263 407.741 90.5704 407.84 90.8589C407.938 91.1475 408.102 91.4095 408.318 91.6243C408.533 91.839 408.796 92.0009 409.084 92.0972C409.372 92.1935 409.679 92.2217 409.98 92.1796C410.558 92.1767 411.135 92.1091 411.698 91.9779V95.4441C410.428 95.6407 409.145 95.7388 407.86 95.7375C407.324 95.8176 406.777 95.784 406.254 95.6387C405.732 95.4933 405.246 95.2396 404.827 94.8939C404.409 94.5482 404.067 94.1181 403.825 93.6316C403.582 93.1451 403.444 92.613 403.419 92.0695C402.197 93.5456 400.57 94.6296 398.74 95.1873C396.718 95.9172 394.584 96.2835 392.435 96.2694C389.371 96.5327 386.325 95.5777 383.955 93.6101C383.284 92.9394 382.758 92.1375 382.409 91.2541C382.06 90.3707 381.896 89.4248 381.927 88.475C381.872 87.4235 382.072 86.3743 382.508 85.4165C382.943 84.4587 383.603 83.6206 384.43 82.9731C387.043 81.422 390.004 80.5577 393.038 80.4604L398.649 79.8737C399.784 79.801 400.898 79.5278 401.939 79.0667C402.335 78.8348 402.658 78.4942 402.868 78.0849C403.078 77.6756 403.168 77.2146 403.127 76.7559C403.127 73.9071 400.647 72.4888 395.688 72.5011C390.51 72.5011 387.86 74.2127 387.738 77.6362ZM403.145 85.2105V81.891C401.721 82.8808 400.034 83.4176 398.302 83.4316L392.819 84.1102C391.268 84.2406 389.761 84.6967 388.396 85.4489C387.92 85.763 387.535 86.1985 387.281 86.7109C387.027 87.2233 386.914 87.7941 386.953 88.365C386.953 88.9617 387.106 89.5481 387.397 90.0683C387.689 90.5886 388.108 91.0251 388.616 91.336C389.918 92.1463 391.434 92.5426 392.965 92.4731C395.555 92.5666 398.11 91.8487 400.276 90.419C401.157 89.8685 401.883 89.1009 402.385 88.1893C402.887 87.2776 403.149 86.2522 403.145 85.2105Z"
        fill="#1D1D1B"
      />
      <path
        d="M417.291 87.2829H422.226C422.215 87.9563 422.366 88.6224 422.665 89.2255C422.964 89.8285 423.402 90.351 423.943 90.7491C425.823 92.0272 428.075 92.6343 430.34 92.4731C435.603 92.4731 438.235 91.122 438.235 88.42C438.268 88.0022 438.2 87.5828 438.038 87.1967C437.876 86.8106 437.624 86.4691 437.303 86.2008C436.102 85.4821 434.75 85.0551 433.355 84.9537L425.223 83.8717C423.416 83.6854 421.712 82.9422 420.343 81.7443C419.663 81.1581 419.12 80.4284 418.754 79.6073C418.387 78.7861 418.206 77.8939 418.223 76.9943C418.188 75.7878 418.459 74.592 419.012 73.5201C419.566 72.4483 420.382 71.5358 421.385 70.8688C423.955 69.2499 426.962 68.4684 429.993 68.6314C437.888 68.6314 442.091 71.5474 442.603 77.3428H437.65C437.613 76.6539 437.411 75.9842 437.061 75.3904C436.711 74.7966 436.224 74.2961 435.64 73.9315C433.914 72.886 431.914 72.3875 429.901 72.5011C425.387 72.5011 423.103 73.8216 423.103 76.4442C423.08 76.8456 423.15 77.2469 423.309 77.6162C423.468 77.9856 423.71 78.3126 424.017 78.5715C425.039 79.2212 426.208 79.5997 427.416 79.6719L434.397 80.6072C440.404 81.4019 443.413 83.6944 443.425 87.4846C443.496 88.7957 443.214 90.1017 442.609 91.2663C442.005 92.4308 441.1 93.4112 439.99 94.1052C437.091 95.7409 433.788 96.5109 430.468 96.3244C422.11 96.3244 417.717 93.3106 417.291 87.2829Z"
        fill="#1D1D1B"
      />
      <path
        d="M453.897 69.53V61.5705H458.831V69.53H466.142V73.3813H458.831V89.3553C458.831 91.1893 460.166 92.1796 462.816 92.1796C463.957 92.1647 465.094 92.0481 466.215 91.8311V95.9943C464.399 96.2475 462.565 96.3578 460.732 96.3244C456.175 96.3244 453.903 94.2826 453.915 90.199V73.3813H448.615V69.53H453.897Z"
        fill="#1D1D1B"
      />
      <path
        d="M473.615 65.2752V59.7732H478.55V65.2752H473.615ZM473.615 95.4441V69.53H478.55V95.4441H473.615Z"
        fill="#1D1D1B"
      />
      <path
        d="M488.254 95.4441V69.53H493.17V73.4364C495.217 70.2331 498.598 68.6314 503.313 68.6314C506.069 68.4792 508.8 69.2254 511.099 70.7588C512.148 71.588 512.98 72.6605 513.524 73.8841C514.069 75.1076 514.309 76.4457 514.224 77.7829V95.4441H509.289V78.7732C509.289 74.7996 506.743 72.8067 501.65 72.7945C499.512 72.7212 497.417 73.4027 495.729 74.7202C494.889 75.4211 494.221 76.3069 493.777 77.3088C493.333 78.3107 493.126 79.4017 493.17 80.4972V95.4441H488.254Z"
        fill="#1D1D1B"
      />
      <path
        d="M547.083 69.53H551.999V93.2617C551.999 97.9017 550.83 101.258 548.49 103.275C546.151 105.293 542.295 106.32 536.976 106.32C534.19 106.451 531.407 105.995 528.807 104.981C527.473 104.403 526.319 103.474 525.466 102.293C524.614 101.111 524.095 99.721 523.964 98.2684H528.899C529.084 99.0133 529.439 99.7046 529.937 100.287C530.436 100.87 531.062 101.328 531.768 101.625C533.597 102.271 535.533 102.557 537.47 102.468C540.154 102.669 542.814 101.837 544.908 100.139C545.642 99.4316 546.216 98.5743 546.591 97.6251C546.966 96.6759 547.134 95.657 547.083 94.6372V90.8775C545.955 92.4724 544.439 93.7511 542.68 94.5922C540.921 95.4332 538.976 95.8086 537.031 95.6826C533.544 95.8126 530.121 94.7236 527.345 92.6015C525.928 91.2878 524.796 89.6938 524.023 87.9199C523.249 86.1461 522.85 84.2307 522.85 82.2944C522.85 80.3581 523.249 78.4429 524.023 76.669C524.796 74.8952 525.928 73.3012 527.345 71.9875C530.109 69.8708 533.52 68.7819 536.995 68.9064C541.807 68.9064 545.17 70.3737 547.083 73.308V69.53ZM547.083 82.3679C547.126 81.1368 546.922 79.9096 546.483 78.7595C546.043 77.6094 545.377 76.5599 544.524 75.6738C542.591 73.9936 540.119 73.0688 537.561 73.0688C535.004 73.0688 532.532 73.9936 530.598 75.6738C529.749 76.5615 529.086 77.6116 528.65 78.7616C528.213 79.9116 528.012 81.1381 528.058 82.3679C528.006 83.5901 528.204 84.81 528.641 85.952C529.078 87.094 529.744 88.1338 530.598 89.0069C532.553 90.6419 535.017 91.5374 537.561 91.5374C540.106 91.5374 542.57 90.6419 544.524 89.0069C545.378 88.1369 546.046 87.1007 546.486 85.9621C546.926 84.8235 547.129 83.6066 547.083 82.3862V82.3679Z"
        fill="#1D1D1B"
      />
      <defs>
        <linearGradient
          id="paint0_linear_603_315"
          x1="37.6108"
          y1="98.3783"
          x2="161.776"
          y2="98.3783"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#E5007E" />
          <stop offset="0.3" stop-color="#E3007D" />
          <stop offset="0.46" stop-color="#DB017B" />
          <stop offset="0.59" stop-color="#CD0376" />
          <stop offset="0.7" stop-color="#BA0570" />
          <stop offset="0.8" stop-color="#A10768" />
          <stop offset="0.89" stop-color="#820B5E" />
          <stop offset="0.97" stop-color="#5E0F53" />
          <stop offset="1" stop-color="#51104F" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_603_315"
          x1="82.2044"
          y1="156.405"
          x2="10.3341"
          y2="32.3527"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#FFEC00" />
          <stop offset="0.31" stop-color="#FDE900" />
          <stop offset="0.49" stop-color="#F8E100" />
          <stop offset="0.65" stop-color="#EED300" />
          <stop offset="0.79" stop-color="#E0BE00" />
          <stop offset="0.91" stop-color="#CFA400" />
          <stop offset="1" stop-color="#BF8D00" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_603_315"
          x1="9.97977"
          y1="44.1111"
          x2="155.784"
          y2="44.1111"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#009CDF" />
          <stop offset="0.2" stop-color="#0099DB" />
          <stop offset="0.41" stop-color="#0091CE" />
          <stop offset="0.63" stop-color="#0083B8" />
          <stop offset="0.85" stop-color="#006F9B" />
          <stop offset="1" stop-color="#006083" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgGBLivestream;
