import React from 'react';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { SEO, Layout, ContentBody, ContentAside } from 'components';
import { FacebookIcon, LinkedinIcon, TwitterIcon } from 'images/Blog';
import GBLivestream from 'images/Livestream/GBLivestream';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { LinkedinShareButton, FacebookShareButton, TwitterShareButton } from 'react-share';

export default ({ data, location }) => {
  console.log(data);
  const { livestream } = data;
  const {
    title,
    extendedTitle,
    summary,
    mainImage,
    vimeoVideo,
    _rawBody,
    _rawCompanyLogos
  } = livestream;
  return (
    <Layout>
      <SEO
        pageSpecificTitle={title}
        pageSpecificDescription={summary}
        pageSpecificThumbnail={mainImage.image.asset.fluid.src}
        pageSpecificThumbnailAlt={mainImage.image.alt}
        pathname={location.pathname}
      />
      <HeroWrapper>
        <HeroText>
          {(extendedTitle || title).split('<br />').map((_text) => (
            <h1>{_text}</h1>
          ))}
        </HeroText>
        <SocialMediaButtons>
          <h3 style={{ textTransform: 'uppercase', margin: '59px 0 17px' }}>Share</h3>
          <div>
            <LinkedinShareButton url={location.href}>
              <SocialMediaButton>
                <LinkedinIcon />
              </SocialMediaButton>
            </LinkedinShareButton>
            <FacebookShareButton url={location.href}>
              <SocialMediaButton>
                <FacebookIcon />
              </SocialMediaButton>
            </FacebookShareButton>
            <TwitterShareButton url={location.href}>
              <SocialMediaButton>
                <TwitterIcon />
              </SocialMediaButton>
            </TwitterShareButton>
          </div>
        </SocialMediaButtons>
        {vimeoVideo && (
          <div
            className="video-without-caption"
            style={{
              position: 'relative',
              paddingTop: '56.25%',
              maxWidth: '1180px',
              height: '100%',
              width: '100%',
              margin: '1rem 0 2rem 0'
            }}>
            <iframe
              src={vimeoVideo.url}
              width="820"
              height="461"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            />
          </div>
        )}
      </HeroWrapper>
      <ContentWrapper>
        <ContentBody blockContent={_rawBody} hideSocials smallerMargin slug=""/>
        {_rawCompanyLogos && (
          <ContentBody blockContent={_rawCompanyLogos} hideSocials directionRow smallerMargin slug="" />
        )}
        <BroughtToYou>
          <h4>Live Stream Broadcast is brought to you by</h4>
          <Link to="/contact-us">
            <GBLivestream />
          </Link>
        </BroughtToYou>
      </ContentWrapper>
    </Layout>
  );
};

export const HeroWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 7rem;
  position: relative;
  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  @media screen and (min-width: 820px) {
    margin-top: 14rem;
  }
`;

export const HeroText = styled.div`
  color: #000;
  bottom: 3.05rem;
  max-width: 58.75rem;
  h1 {
    font-size: 2rem;
    margin: 0 0.25rem;
    font-weight: 400;
    text-align: center;
    @media screen and (min-width: 820px) {
      font-size: 3rem;
      margin: 0;
    }
  }
  h3 {
    font-size: 1.125rem;
    text-transform: uppercase;
    font-weight: 700;
    display: inline-block;
  }
`;

const SocialMediaButtons = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  > div {
    width: 6.375rem;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
  }
  h3 {
    margin-top: 1rem !important;
  }
  @media screen and (min-width: 820px) {
    h3 {
      margin-top: 4rem !important;
    }
  }
`;

const BroughtToYou = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 4rem 0 0 0;
  width: 100%;
  h4 {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    line-height: 30px;
  }
  svg {
    max-width: 550px;
    width: 100%;
  }
  @media screen and (min-width: 820px) {
    margin: 4rem 0;
    h4 {
      margin-bottom: 2rem;
    }
  }
`;

const SocialMediaButton = styled(motion.div).attrs({
  whileHover: {
    scale: 1.15
  },
  whileTap: {
    scale: 0.85
  }
})`
  display: flex;
  align-items: center;
  background: linear-gradient(215.86deg, #e4097e 27.63%, #ffef00 153.42%);
  border-radius: 50%;
  height: 1.5rem;
  justify-content: center;
  outline: none;
  width: 1.5rem;
`;

export const MultiColouredBottomBorder = styled.div`
  background: linear-gradient(269.94deg, #e4097e 63.91%, #ffef00 100%);
  height: 0.188rem;
  margin: 0.063rem 0 0.75rem;
  width: calc(100% + 1rem);
`;

export const HeroImg = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
`;

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  width: 100%;
  padding: 0 1rem;
`;

export const query = graphql`
  query($slug: String!) {
    livestream: sanityLivestream(slug: { current: { eq: $slug } }) {
      title
      slug {
        current
      }
      mainImage {
        image {
          asset {
            fluid(maxWidth: 1440, maxHeight: 600) {
              ...GatsbySanityImageFluid
            }
          }
        }
        title
        alt
      }
      vimeoVideo {
        url
      }
      _rawBody
      _rawCompanyLogos
    }
  }
`;
